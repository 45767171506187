// Bootstrap brand color customization

/*     brand Colors              */

$brand-primary:              $black !default;
$brand-info:                 $black !default;
$brand-success:              $black !default;
$brand-warning:              $black !default;
$brand-danger:               $black !default;
$brand-rose:                 $black !default;
$brand-inverse:              $white !default;
